.Container {
    background-color: #FFFFFF;
    margin: auto;
    border-radius: 7px;
    box-shadow:  0 4px 34px rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 80px;
}

.Container p {
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
}

.Created {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Created img {
    margin-block: 30px;
}