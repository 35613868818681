.Header{
    font-family: 'Libre Baskerville', serif;
    background-color: #FFFFFF;
    min-height: 91px;
    display: flex;
    align-items: center;
}

.Header div {
    display: flex;
    justify-content: space-between;
}

.Header p {
    font-size: 20px;
}

.Header ul {
    display: flex;
}

.Header ul li {
    padding-inline: 20px;
    list-style-type: none;
}

.Header ul li a {
    text-decoration: none;
    color: black ;
}

.Header a.activeLink {
    font-weight: bold;
}

@media all and (max-width: 600px) {
    .Header div {
        flex-direction: column;
        align-items: center;
    }

    .Header img {
        margin-top: 20px;
        width: 50%;
    }

    .Header ul li {
        padding-block: 20px;
    }
}
