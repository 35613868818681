.BookRatingForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 465px
}

.BookRatingForm form {
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #F9F3EE;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 32px;
    margin-top: 50px;

}

.BookRatingForm form p {
    font-family: "Libre Baskerville", serif;
    font-size: 18px;
    margin-bottom: 22px;
}

.BookRatingForm form input[type="radio"] {
    position: absolute;
    left: -10000px
}
.BookRatingForm form input[type="submit"] {
    padding: 20px 23px;
    border: none;
    background-color: #D9A963;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
}

.Stars, .Stars label{
    display: flex;
    margin-bottom: 22px;
}

@media all and (max-width: 600px) {

}